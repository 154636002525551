import React from "react";
import TitleText from "./common/TitleText";

class FeedSourceComponent extends React.Component {

  constructor(props) {
    super();
    this.state = {
      feedSource: [],
      feedId: null
    };
  }


  componentDidMount() {
    fetch('/v1/feeds/' + this.props.feedId)
      .then( data => {
        return data.json()
      }).then( feed => {
        console.log(feed);
        this.setState({feedSource: feed})
      })
      this.setState({feedId: this.props.feedId})
  }

  render() {
      return (
        <>
        <TitleText icon="bi bi-rss" title={this.state.feedSource.title} /> 
        </>);
  }

}

export default FeedSourceComponent;