import React, { Fragment }  from "react";
import Checkbox from "./common/Checkbox";

class TagListComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            checkedItems: new Map(),
        }

        this.handleChange = this.handleChange.bind(this);


    }


    componentDidMount() {

        let selectedTagsJson = localStorage.getItem('tags');

        console.log(selectedTagsJson);
        let selectedTags;
        if ( selectedTagsJson == null ) {
            selectedTags = [];
        }
        else {
            selectedTags = JSON.parse(selectedTagsJson);
        }
        let checkedTags = new Map();

        for( let i = 0 ; i < selectedTags.length ; i++ ) {
            const tag = selectedTags[i];
            checkedTags = checkedTags.set(tag, true);
        }

        this.setState( {checkedItems: checkedTags});

    }

    handleChange(e) {
        const item = e.target.name;
        const isChecked = e.target.checked;

        console.log('item:', item);
        console.log('isChecked:', isChecked);
        this.setState(prevState => ({ checkedItems: prevState.checkedItems.set('' + item, isChecked) }));
        console.log('state:', this.state);
        this.updateLocalStorage(e);
    }

    updateLocalStorage(event) {
        console.log('event', event);
        console.log('target', event.target);

        console.log('target', event.target.name);
        let selectedTagsJson = localStorage.getItem('tags');

        console.log(selectedTagsJson);
        let selectedTags;
        if ( selectedTagsJson == null ) {
            selectedTags = [];
        }
        else {
            selectedTags = JSON.parse(selectedTagsJson);
        }

        console.log('selectedTags', selectedTags);

        const tagId = event.target.name;

        console.log('tagId', tagId);

        console.log('is checked', event.target.checked);
        if( event.target.checked) {

            selectedTags.push(tagId);
        }
        else {
            const index = selectedTags.indexOf(tagId);
            if (index !== -1) selectedTags.splice(index, 1);
        }

        console.log('selectedTags', selectedTags);

        localStorage.setItem('tags', JSON.stringify(selectedTags));
        localStorage.getItem('tags');
    }

    render() {

        const filteredTags = this.props.tags.filter(t => {
            if (t === undefined) {
                return false;
            }

            if (t.name === undefined) {
                return false;
            }
            return t.name.toLowerCase().startsWith(this.props.filter)
        });


        const tagList = filteredTags.map(ft => {
            return (
                <div key={'tag' + ft.id}>
                    <p><Checkbox name={'' + ft.id} checked={this.state.checkedItems.get('' + ft.id)} onChange={this.handleChange} /><a href={'/tags/' + ft.id}>{ft.name}</a></p>
                </div>
            );
        })

        const tagHtml = tagList.length !== 0 ? (


            <div>

                <div className="card">

                    <div className="card-header">
                        {this.props.filter.toUpperCase()}
                    </div>
                    <div className="card-body container">
                        {tagList}
                    </div>
                </div>
            </div>
        ) : (<Fragment></Fragment>);


        return (
            <Fragment>
                {tagHtml}

            </Fragment>

        );
    }

}

export default TagListComponent;