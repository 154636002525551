import React, { Fragment } from "react";
import FeedSourceComponent from "./FeedSourceComponent.jsx";

import FeedEntriesListingComponent from "./FeedEntriesListingComponent.jsx";
import LoadingComponent from "./common/LoadingComponent";
import PaginationComponent from "./common/PaginationComponent";

class FeedSourceEntriesComponent extends React.Component {

  constructor() {
    super();
    this.state = {
      feedSources: [],
      feedId: {},
      newsFeeds: [],
      page: 0,
      isLoading: true,
    };

    this.nextPage = this.nextPage.bind(this)
    this.prevPage = this.prevPage.bind(this);
    this.getFeedEntries = this.getFeedEntries.bind(this);
  }

  nextPage() {
    this.setState({page: this.state.page + 1})
    console.log('page:', this.state.page)
    this.getFeedEntries()

  }

  prevPage() {

    if (this.state.page > 0) {
      this.setState({page: this.state.page - 1})
      this.getFeedEntries();

    }

    console.log('page:', this.state.page)

  }
  componentDidMount() {

    this.getFeedEntries()
  }

  getFeedEntries() {
    const { match: { params } } = this.props;

    this.setState({ feedId: params.feedId })

    fetch('/v1/feeds/' + params.feedId + '/entries?page=' + this.state.page)
        .then(data => {
          return data.json()
        }).then(entries => {

      console.log('entries', entries);
      this.setState({ newsFeeds: entries.entries });
      this.setState( { isLoading: false});
    })
  }

  setFeedId(feedId) {
    this.setState({ feedId: feedId })
  }
  componentDidUpdate(prevProps) {
    window.scrollTo(0, 0)
  }
  render() {
    const { match: { params } } = this.props;
    let prevStyle = "page-item " + this.state.page === "0" ? "disabled" : "";

    return (
      <Fragment>
        <div className="row text-center">

            <FeedSourceComponent feedId={params.feedId} action={this.setFeedId} />
        </div>
        <div>
          <div className="row text-left">

            <LoadingComponent isLoading={this.state.isLoading}>
            <FeedEntriesListingComponent items={this.state.newsFeeds} />


            <PaginationComponent prevPage={this.prevPage} nextPage={this.nextPage} prevStyle={prevStyle} />
            </LoadingComponent>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default FeedSourceEntriesComponent; 