import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import TopTagsComponent from "../TopTagsComponent";
import Button from "./Button";

const LeftNavigationComponent = () => (
    <Fragment>
        <div className="container title">
            <Link to="/" className="navbar-brand">
                <h1 className="title"><i className="fa fa-rss" aria-hidden="true"></i> Feed.rocks</h1>
            </Link>
            <ul className="list-group">

                <li className="list-group-item menu">

                    <Button href="/feeds" title="Feeds" />
                </li>
                <li className="list-group-item menu">
                    <TopTagsComponent />
                </li>
                <li className="list-group-item menu">
                    <Button href="/tags" title="Tags" />
                </li>
                <li className="list-group-item menu">
                    <Button href="/personal" title="Favourite tags" />
                </li>

            </ul>
        </div>

    </Fragment>
);


export default LeftNavigationComponent;