import React, { Fragment } from "react";
import TagListComponent from "./TagListComponent";
import TitleText from "./common/TitleText";
import LoadingComponent from "./common/LoadingComponent";


class TagsComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            tags: [],
            isLoading: true,
        };

    }

    componentDidMount() {
        fetch('/v1/tags/stats?size=400')
            .then( data => {

                return data.json()
            }).then( tags => {

            // console.log('tags:', tags);
            this.setState({tags: tags.tags});
            this.setState( {isLoading: false});
        });


    }



    render() {


        const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');
        // const alphabet = 'abcde'.split('');
        let tags = this.state.tags;
        console.log('tags:', tags);


        const alphaArray = alphabet.map(c => {return  (
            <div key={c} className="text-left">
                <TagListComponent key={c} tags={this.state.tags} filter={c} />
            </div>)}
            );

        return (
            <Fragment>
                <TitleText title="Tags" />
                <LoadingComponent isLoading={this.state.isLoading} >
                    <p className="lead">Selecting your favourites tags, you could choose which feed entries are shown on the <a href="/personal">favourite tag page</a></p>
                   {alphaArray}
                </LoadingComponent>
            </Fragment>
        );
    }
}

export default TagsComponent;