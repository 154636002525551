import React, {Fragment} from "react";
import TitleText from "./common/TitleText";
import PaginationComponent from "./common/PaginationComponent";
import LoadingComponent from "./common/LoadingComponent";
import FeedSource from "./common/FeedSource";


class FeedListComponent extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            feedSources: [],
            page: 1,
            size: 30,
            isLoading: true
        };
        this.nextPage = this.nextPage.bind(this)
        this.prevPage = this.prevPage.bind(this);
        this.getFeedSources = this.getFeedSources.bind(this);

    }
    nextPage() {
        this.setState({page: this.state.page + 1})
        console.log('page:', this.state.page)
        this.getFeedSources()

    }

    prevPage() {

        if (this.state.page > 0) {
            this.setState({page: this.state.page - 1})
            this.getFeedSources();

        }

        console.log('page:', this.state.page)

    }
    componentDidMount() {
        this.getFeedSources();

    }


    getFeedSources() {
        // console.log("NewsfeedSourceComponent: componentdid mount")
        fetch('/v1/feeds?size=' + this.state.size + '&page=' + this.state.page)
            .then(data => {
                return data.json()
            }).then(entries => {
            // console.log("feed sources", entries);
            let sources = entries.feeds.map(feed => {
                // console.log('feed', feed)
                // console.log('feed title', feed.title)
                if (feed.title) {

                    const lastUpdated = feed.lastUpdated != null ? Date.parse(feed.lastUpdated) : Date.now();
                    return (
                        <>

                        <FeedSource id={feed.id} title={feed.title} lastUpdated={lastUpdated} />
                        </>
                    );
                }
                else {
                    return (<Fragment />)
                }
            })

            this.setState({ feedSources: sources });
            this.setState( {isLoading: false});
        })
    }

    render() {
        let prevStyle = "page-item " + this.state.page === "0" ? "disabled" : "";

        return (
            <Fragment>
                <TitleText title="Feeds" />
                <LoadingComponent isLoading={this.state.isLoading} >
                <ul>
                    {this.state.feedSources}
                </ul>

                <PaginationComponent prevPage={this.prevPage} nextPage={this.nextPage} prevStyle={prevStyle} />
                </LoadingComponent>
            </Fragment>)
    }

}

export default FeedListComponent;