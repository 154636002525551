import React from 'react';
import PropTypes from 'prop-types';

const Button = ({ className = 'btn btn-secondary btn-block', href, title}) => (
    <a className={className} href={href}>{title}</a>);

Button.propTypes = {
    className: PropTypes.string,
    href: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
}

export default Button;