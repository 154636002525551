
import React, {Fragment} from "react";
import PropTypes from "prop-types";

function LoadingComponent(props) {
    const isLoading = props.isLoading;
    if (isLoading) {
        return (
            <div className="-align-center spinner-border text-dark" role="status">
                <span className="sr-only">Loading...</span>
            </div>);
    }
    else {
        return (
            <Fragment>{props.children}</Fragment>
        );
    }
}

LoadingComponent.propTypes = {
    isLoading: PropTypes.bool.isRequired,
}


export default LoadingComponent;