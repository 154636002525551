
import React from "react";
import PropTypes from "prop-types";

function PaginationComponent(props) {


    return (
        <ul className="pagination">
        <li className={props.prevStyle}>
            <button className="page-link" onClick={props.prevPage}>Previous</button>
        </li>
        <li className="page-item">
            <button className="page-link"  onClick={props.nextPage}>Next</button>
        </li>
    </ul>);
}

PaginationComponent.propTypes = {
    prevStyle: PropTypes.string,
    prevPage: PropTypes.func.isRequired,
    nextPage: PropTypes.func.isRequired
}

export default PaginationComponent;