import React, { Fragment }  from "react";

class TopTagsComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            tags: [],
        };
    }


    componentDidMount() {
        fetch('/v1/tags/stats')
            .then( data => {

                return data.json()
            }).then( tags => {

            // console.log('tags:', tags);
            this.setState({tags: tags.tags});
        })
    }

    render() {

        const tagsList = this.state.tags.map(t => {
            // console.log('tag', t);
            return (<a key={t.id} href={'/tags/' + t.id} className="dropdown-item">{t.name} ({t.count})</a>)
        });
        return (
            <Fragment>
                <div className="dropdown">
                    <button className="btn btn-secondary btn-block dropdown-toggle" type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Popular tags
                    </button>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        {tagsList}
                    </div>
                </div>
            </Fragment>
        );
    }

}

export default TopTagsComponent;