import React, { Fragment } from "react";

import FeedEntriesListingComponent from "./FeedEntriesListingComponent.jsx";
import TitleText from "./common/TitleText";
import LoadingComponent from "./common/LoadingComponent";
import PaginationComponent from "./common/PaginationComponent";

class TagEntriesComponent extends React.Component {

    constructor() {
        super();
        this.state = {
            tagId: 0,
            newsFeeds: [],
            page: 0,
            tagName: '',
            loading: true,

        };

        this.nextPage = this.nextPage.bind(this)
        this.prevPage = this.prevPage.bind(this);
        this.getFeedEntries = this.getFeedEntries.bind(this);
    }

    nextPage() {
        this.setState({page: this.state.page + 1})
        console.log('page:', this.state.page)
        this.getFeedEntries()

    }

    prevPage() {

        if (this.state.page > 0) {
            this.setState({page: this.state.page - 1})
            this.getFeedEntries();

        }

        console.log('page:', this.state.page)

    }
    componentDidMount() {

        this.getFeedEntries()
    }

    getFeedEntries() {
        const { match: { params } } = this.props;

        this.setState({ tagId: params.tagId })

        fetch('/v1/tags/' + params.tagId + '/entries?page=' + this.state.page)
            .then(data => {
                return data.json()
            }).then(entries => {

            console.log('entries', entries);
            this.setState({ newsFeeds: entries.entries });
            this.setState({loading: false});
            console.log({entries});
        })

        fetch( '/v1/tags/' + params.tagId)
            .then(data => {
                return data.json();
            })
            .then( tag => {
                console.log('tag:', tag);
                this.setState({tagName: tag.name});
            })
    }

    setFeedId(feedId) {
        this.setState({ feedId: feedId })
    }

    componentDidUpdate(prevProps) {
        window.scrollTo(0, 0)
    }

    render() {
        let prevStyle = "page-item " + this.state.page === "0" ? "disabled" : "";



        return (
            <Fragment>
                <TitleText title={'#' + this.state.tagName} />
                <LoadingComponent isLoading={this.state.loading} >
                    <div>
                        <div className="row text-left">
                            <FeedEntriesListingComponent items={this.state.newsFeeds} />
                            <PaginationComponent prevPage={this.prevPage} nextPage={this.nextPage} prevStyle={prevStyle} />
                        </div>
                    </div>
                </LoadingComponent>
            </Fragment>
        )
    }
}

export default TagEntriesComponent;