import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import './App.css';
import NewsFeedsComponent from './components/NewsfeedComponent.jsx'
import FeedSourceEntriesComponent from './components/FeedSourceEntriesComponent.jsx'
import TagsComponent from './components/TagsComponent.jsx'

import 'font-awesome/css/font-awesome.min.css';

import {
  BrowserRouter as Router,
  Switch,
  Route} from "react-router-dom";
import TagEntriesComponent from "./components/TagEntriesComponent";
import FavoriteTagFeedEntries from "./components/FavoriteTagFeedEntries";
import LeftNavigationComponent from "./components/common/LeftNavigationComponent";
import Footer from "./components/common/Footer";
import FeedListComponent from "./components/FeedListComponent";
import JavascriptTimeAgo from 'javascript-time-ago'

import en from 'javascript-time-ago/locale/en'
import "fontsource-inter" // Defaults to weight 400 with all styles included.


function App() {
  JavascriptTimeAgo.addLocale(en)

  return (

    <Router>
      <div className="App">
        <div className="custom-box row">

          <div className="col-sm-3 ">
            <LeftNavigationComponent />
          </div>
          <div className="col-sm-9 container content">
            <Switch>
              <Route exact path="/">
                <NewsFeedsComponent />
              </Route>
              <Route path="/about">
                <About />
              </Route>
              <Route path="/feeds/:feedId" component={FeedSourceEntriesComponent} />

              <Route path="/tags/:tagId" component={TagEntriesComponent} />
              <Route path="/tags/" component={TagsComponent} />
              <Route path="/personal/" component={FavoriteTagFeedEntries} />
              <Route path="/feeds" component={FeedListComponent} />

            </Switch>
          </div>
        </div>

        <Footer />

      </div>
    </Router>

  );
}




function About() {
  return (
    <div>
      <h2>About</h2>
    </div>
  );
}


export default App;
