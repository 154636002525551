import React from 'react';
import PropTypes from 'prop-types';
import ReactTimeAgo from 'react-time-ago/commonjs/ReactTimeAgo';


const FeedSource = ({ id, title, lastUpdated }) => (
  <li key={id} className="list-group-item d-flex justify-content-between align-items-center">
    <a key={id} className="" href={'/feeds/' + id} >{title}</a>

    <ReactTimeAgo date={lastUpdated} />
  </li>
);

FeedSource.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  lastUpdated: PropTypes.any,
}

export default FeedSource;