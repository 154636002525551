import React from "react";
import FeedEntriesListingComponent from "./FeedEntriesListingComponent";
import TitleText from "./common/TitleText";
import LoadingComponent from "./common/LoadingComponent";

class FavoriteTagFeedEntries extends React.Component {

    constructor(props) {
        super();
        this.state = {
            entries: [],
            isLoading: true,
        }
    }

    componentDidMount() {
        const tagsJson = localStorage.getItem('tags');
        console.log(tagsJson);
        if( tagsJson != null ) {
            const tags = JSON.parse(tagsJson);

            let tagParams = tags.map( t => {return "tagId=" + t +'&'});
            console.log('tagParams:', tagParams);

            fetch( '/v1/feeds/entries?' + tagParams.join(''))
                .then (  response => {
                    return response.json();
                })
                . then( entries => {
                    console.log('entries', entries);

                    this.setState( { entries : entries.entries, isLoading: false});
                })
        }

    }

    render() {
        return (
            <>
                <TitleText title="Favourite Tags" />
                <div className="row text-center">
                    <LoadingComponent isLoading={this.state.isLoading} >

                    <FeedEntriesListingComponent items={this.state.entries}/>
                    </LoadingComponent>
                </div>
            </>)
    }
}

export default FavoriteTagFeedEntries;