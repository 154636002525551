import React, {Fragment} from "react";
import FeedEntriesListingComponent from "./FeedEntriesListingComponent";
import LoadingComponent from "./common/LoadingComponent";

class NewsfeedComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            newsFeeds: [],
            entries: [],
            page: 0,
            isLoading: true,
        };

        this.nextPage = this.nextPage.bind(this)
        this.prevPage = this.prevPage.bind(this);
    }

    nextPage() {
        this.setState({page: this.state.page + 1})
        console.log('page:', this.state.page)
        this.getFeeds();

    }

    prevPage() {

        if (this.state.page > 0) {
            this.setState({page: this.state.page - 1})
        }
        this.getFeeds();

        console.log('page:', this.state.page)

    }

    componentDidMount() {
        this.getFeeds();

        this.myInterval = setInterval(() => {
            this.getFeeds()
        }, 60000)
    }

    componentWillUnmount() {
        clearInterval(this.myInterval)
    }

    getFeeds() {
        console.log('/v1/feeds/entries?page=' + this.state.page )
        fetch('/v1/feeds/entries?page=' + this.state.page)
            .then(data => {
                return data.json()
            }).then(entries => {
            console.log('entries', entries);
            this.setState({entries: entries.entries})
            this.setState( {isLoading: false});

        })
    }

    render() {
        let prevStyle = "page-item " + this.state.page === 0 ? "disabled" : "";
        return (
            <Fragment>
                <div className=" row text-center">
                    <h3 className="feedTitle space">All</h3>
                </div>
                <LoadingComponent isLoading={this.state.isLoading} >

                <div className=" row text-center">
                    <FeedEntriesListingComponent items={this.state.entries}/>
                </div>

                <ul className="pagination">
                    <li className={prevStyle}>
                        <button className="page-link" onClick={this.prevPage}>Previous</button>
                    </li>
                    <li className="page-item">
                       <button className="page-link"  onClick={this.nextPage}>Next</button>
                    </li>
                </ul>
                </LoadingComponent>
            </Fragment>

        )

    }
}

export default NewsfeedComponent;
