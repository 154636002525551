import React from 'react';
import PropTypes from 'prop-types';

const TitleText = ({ className = 'feedTitle space', title, icon}) => (
    <div className="row text-center">
        <h3 className={className}>
            <i class={icon}></i> {title}
        </h3>
    </div>
);

TitleText.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string.isRequired,
    icon: PropTypes.string,

}

export default TitleText;