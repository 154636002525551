import React from "react";
import ReactTimeAgo from "react-time-ago";

class FeedEntriesListingComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            pageNum: 0,
        }
        this.nextPage = this.nextPage.bind(this);
    }

    nextPage() {
        this.setState({pageNum: this.state.pageNum + 1})
        console.log('nextpage:', this.state.pageNum)

    }

    render() {
        console.log('props', this.props);
        console.log('items', this.props.items);

        if ( this.props.items == null || this.props.items === undefined ) {
            return  ( <div><h2>No entries found</h2></div>);
        }
        let newsEntries = this.props.items.map(entry => {

            const tags = entry.tags.map(tag => {return (
                <a href={'/tags/' + tag.id}>
                    <span className="badge badge-secondary space">#{tag.name}</span>
                </a>)
            });

            return (
                <div className="card list-entry feed-entry" key={entry.id}>
                    <div className="card-body">
                        <a href={entry.url} target="_blank" rel="noopener noreferrer"><h5
                            className="cardTitle text-left">{entry.title}</h5></a>
                        <p className="text-left text-justify truncate">{entry.description}</p>
                        <p className="text-left source">{tags}</p>
                        <p className="text-right source"><a href={'/feeds/' + entry.feedId}>{entry.feedName}</a> - <small>
                            <ReactTimeAgo date={Date.parse(entry.publishedDate)} /></small></p>

                    </div>
                </div>
            );
        })

        return (
            <div className="box-custom">
                {newsEntries}
            </div>)
    }


}

export default FeedEntriesListingComponent; 